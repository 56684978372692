import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { SimbriefFlightPlanGeneral } from '../entities/simbrief/general';

interface Props {
  general: SimbriefFlightPlanGeneral;
}

export const PerformancePanel: React.FC<Props> = ({ general }): JSX.Element => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          CI
        </Grid>

        <Grid item xs={6}>
          <Box className="value">{general.costindex}</Box>
        </Grid>

        <Grid item xs={6}>
          Initial Altitude
        </Grid>

        <Grid item xs={6}>
          <Box className="value">FL{+general.initial_altitude / 100}</Box>
        </Grid>

        <Grid item xs={6}>
          Avg Wind
        </Grid>

        <Grid item xs={6}>
          <Box className="value">
            {general.avg_wind_dir}/{general.avg_wind_spd}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
