import { createTheme, responsiveFontSizes } from '@mui/material';

import colors from './colors.module.scss';

const themeHelper = createTheme();

const darkTheme = createTheme({
  palette: {
    text: {
      primary: colors.bodytext_color,
      secondary: colors.bodytextalt_color,
    },
    primary: {
      main: colors.primary_color,
      dark: colors.primary_color,
    },
    secondary: {
      main: colors.secondary_color,
    },
    error: {
      main: colors.error_color,
      dark: colors.error_color,
    },
    warning: {
      main: colors.warning_color,
      dark: colors.warning_color,
    },
    background: {
      default: colors.defaultbackground_color,
      paper: colors.cardbackground_color,
    },
    mode: 'dark',
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Brutal', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: '"Barlow Condensed"',
      fontSize: themeHelper.typography.pxToRem(48),
    },
    h2: {
      fontFamily: '"Barlow Condensed"',
      fontSize: themeHelper.typography.pxToRem(40),
      fontWeight: 400,
      marginBottom: '1rem',
    },
    h3: {
      fontFamily: '"Barlow Condensed"',
      fontSize: themeHelper.typography.pxToRem(32),
      fontWeight: 'normal',
    },
    h4: {
      fontFamily: '"Barlow"',
      fontSize: themeHelper.typography.pxToRem(28),
      fontWeight: 'normal',
    },
    h5: {
      fontFamily: '"Barlow"',
      fontSize: themeHelper.typography.pxToRem(24),
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: '"Barlow"',
      fontSize: themeHelper.typography.pxToRem(20),
      fontWeight: 'bold',
    },
    subtitle1: {
      fontFamily: 'Brutal',
      fontSize: themeHelper.typography.pxToRem(16),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'Brutal',
      fontSize: themeHelper.typography.pxToRem(12),
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    body1: {
      lineHeight: 1.3,
    },
    body2: {
      lineHeight: 1.3,
    },
    button: {
      fontFamily: 'Brutal',
      fontWeight: 'bold',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontWeight: 'bold',
            color: colors.cardbackground_color,
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          fontFamily: 'Barlow',
          fontWeight: 'bold',
          fontSize: '1.5rem',
          margin: '2rem 0',
          color: '#239A95',
          textTransform: 'uppercase',
          paddingLeft: '4px',
          '::before': {
            borderTop: 'thin solid rgb(255, 255, 255, 0.5)',
          },
          '::after': {
            borderTop: 'thin solid rgb(255, 255, 255, 0.5)',
          },
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(darkTheme);
