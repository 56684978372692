import React from 'react';

import Box from '@mui/material/Box';

import { MenuItem } from './MenuItem';

import './navigation.scss';

export const Navigation = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        mr: 2,
        height: '100%',
        alignItems: 'flex-end',
      }}
    >
      <MenuItem active={true} linkText="Briefing" link="/" end={true} />
      <MenuItem active={true} linkText="OFP" link="ofp" end={true} />
      <MenuItem
        active={true}
        linkText="Procedures"
        link="procedures"
        end={true}
      />
    </Box>
  );
};
