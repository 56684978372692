import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from './App';

import './styles/base.css';
import './styles/fonts/fonts.scss';
import './styles/layout.scss';
import './styles/components.scss';
import './styles/custom.scss';
import './assets/flags/sass/flag-icons.scss';

import { store } from './app/state/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
