import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { ChecklistItem } from '../../entities/Procedure';

interface Props {
  checklistItem: ChecklistItem;
  completed: boolean;
  onClick: (task: ChecklistItem, completed: boolean) => void;
}

export const ChecklistItemComponent: React.FC<Props> = ({
  checklistItem,
  completed,
  onClick,
}): JSX.Element => {
  const [_completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    setCompleted(completed);
  }, [completed]);

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <span
          className={`${completed ? 'checklist-done' : ''}`}
          onClick={() => onClick(checklistItem, !_completed)}
        >
          {completed && <div className="line-through">&nbsp;</div>}
          <span className="item">{checklistItem.item}</span>
          <span className="action">{checklistItem.action}</span>
        </span>
      </Box>
    </>
  );
};
