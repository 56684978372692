import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Logo } from './elements/Logo';
import { Navigation } from './elements/Navigation';

export const DefaultHeader = (): JSX.Element => {
  return (
    <Box id="header">
      <Grid container>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignContent: 'center',
          }}
        >
          <Logo />
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            flexDirection: 'rows',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">Flight Simulator Panel</Typography>
        </Grid>

        <Grid item xs={7}>
          <Navigation />
        </Grid>
      </Grid>
    </Box>
  );
};
