import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { SimbriefFlightPlanAirport } from '../entities/simbrief/general';
import { SchedTime } from '../components/SchedTime';

interface Props {
  type: string;
  airport: SimbriefFlightPlanAirport;
  times?: {
    first: { name: string; time: string };
    second: { name: string; time: string };
  };
}

export const AirportPanel: React.FC<Props> = ({
  type,
  airport,
  times,
}): JSX.Element => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <div className="aiport-title icao">{airport.icao_code}</div>
            <div className="aiport-title plan-rwy">{airport.plan_rwy}</div>
          </Box>
        </Grid>

        <Grid item xs={6}>
          {times && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SchedTime
                name={times.first.name}
                timeString={times.first.time}
              />
              <SchedTime
                name={times.second.name}
                timeString={times.second.time}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <div className="airport-info">
            ELEVATION: <span className="value">{airport.elevation}</span> ft
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="airport-info metar">{airport.metar}</div>
        </Grid>
      </Grid>
    </>
  );
};
