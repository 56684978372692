import React, { PropsWithChildren } from 'react';

import Box from '@mui/material/Box';

import './info-panel.scss';

interface Props {
  title: string;
}

export const InfoPanel: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
}): JSX.Element => {
  return (
    <>
      <Box className="panel-container" sx={{}}>
        <Box className="panel-title">{title}</Box>
        {children}
      </Box>
    </>
  );
};
