import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  SimbriefFlightPlanAircraft,
  SimbriefFlightPlanAirport,
  SimbriefFlightPlanFuel,
  SimbriefFlightPlanGeneral,
  SimbriefFlightPlanParams,
  SimbriefFlightPlanTimes,
  SimbriefFlightPlanWeights,
  SimbriefNavlogFix,
} from '../../features/flight-plan/entities/simbrief/general';
import { RootState } from './store';

export interface SimbriefFlightPlan {
  text: {
    plan_html: string;
  };
  params: SimbriefFlightPlanParams;
  general: SimbriefFlightPlanGeneral;
  origin: SimbriefFlightPlanAirport;
  destination: SimbriefFlightPlanAirport;
  alternate: SimbriefFlightPlanAirport;
  navlog: {
    fix: SimbriefNavlogFix[];
  };
  aircraft: SimbriefFlightPlanAircraft;
  fuel: SimbriefFlightPlanFuel;
  times: SimbriefFlightPlanTimes;
  weights: SimbriefFlightPlanWeights;
}

export const simbriefApiSlice = createApi({
  reducerPath: 'simbriefApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://www.simbrief.com/api`,
  }),
  endpoints: (builder) => ({
    getJsonFlightPlan: builder.query<SimbriefFlightPlan, string>({
      query(username) {
        return `/xml.fetcher.php?username=${username}&json=1`;
      },
      transformResponse(response: SimbriefFlightPlan) {
        if (typeof response.general.icao_airline === 'object') {
          response.general.icao_airline = null;
        }

        if (response.general.flight_number === response.aircraft.reg) {
          response.general.flight_number = null;
        }

        response.text.plan_html = response.text.plan_html.replace(
          '<div style="line-height:14px;font-size:13px">',
          '<div>',
        );

        return response;
      },
      keepUnusedDataFor: 3600, // TODO: Use a proper way to keep data (subscriptions?)
    }),
  }),
});

export const getSimbriefFlightPlanState = (state: RootState, params) =>
  simbriefApiSlice.endpoints.getJsonFlightPlan.select(params)(state).data;

export const { useGetJsonFlightPlanQuery } = simbriefApiSlice;
