import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { SimbriefFlightPlanFuel } from '../entities/simbrief/general';

interface Props {
  fuel: SimbriefFlightPlanFuel;
  units?: string;
}

export const FuelPanel: React.FC<Props> = ({
  fuel,
  units = 'lbs',
}): JSX.Element => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          Block fuel
        </Grid>
        <Grid item xs={4}>
          <Box className="value">{Math.ceil(+fuel.plan_ramp / 100) / 10}</Box>
        </Grid>
        <Grid item xs={4}>
          × 1000 {units}
        </Grid>

        <Grid item xs={4}>
          RESERVE
        </Grid>
        <Grid item xs={4}>
          <Box className="value">{fuel.reserve}</Box>
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          ALTN
        </Grid>
        <Grid item xs={4}>
          <Box className="value">{fuel.alternate_burn}</Box>
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          FINRES+ALTN
        </Grid>
        <Grid item xs={4}>
          <Box className="value">
            {Math.ceil((+fuel.reserve + +fuel.alternate_burn) / 100) / 10}
          </Box>
        </Grid>
        <Grid item xs={4}>
          × 1000 {units}
        </Grid>
      </Grid>
    </>
  );
};
