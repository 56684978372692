import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Time } from './Time';
import { Cycle } from './Cycle';
import { Version } from '../../../features/version/Version';

export const DefaultFooter = (): JSX.Element => {
  return (
    <Grid
      container
      id="footer"
      sx={{ bgcolor: 'background.paper', height: 64 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={4}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '0.5rem',
          }}
        >
          <Cycle />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Version />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Time localtime={true} name="Local" />
          <Time localtime={false} name="UTC" />
        </Box>
      </Grid>
    </Grid>
  );
};
