import React, { useState } from 'react';

import Box from '@mui/material/Box';

import { useAppDispatch } from '../../../../app/state/hooks';
import { lastTaskCompletedAdded } from '../../../flight-plan/state/procedure-slice';

import { Task, TaskGroup } from '../../entities/Procedure';
import { TaskItemComponent } from './TaskItem';

interface Props {
  taskGroup: TaskGroup;
}

export const TaskGroupComponent: React.FC<Props> = ({
  taskGroup,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const [completedTasks, setCompletedTasks] = useState<boolean[]>(
    Array(taskGroup.tasks.length).fill(false),
  );

  const handleCompleted = (task: Task, completed: boolean): void => {
    let index = taskGroup.tasks.findIndex((t) => t.id === task.id);

    index = completed ? index + 1 : index;

    const workCompletedTasks = Array(taskGroup.tasks.length)
      .fill(false)
      .fill(true, 0, index);

    dispatch(lastTaskCompletedAdded(task.id));

    setCompletedTasks(workCompletedTasks);
  };

  return (
    <Box
      sx={{ marginBottom: 5 }}
      className={`${taskGroup.tasksAreOptional ? 'taskgroup-optional' : ''}`}
    >
      {taskGroup.title && taskGroup.tasksAreOptional && (
        <Box
          sx={{
            backgroundColor: '#333',
            color: '#999',
            padding: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>{taskGroup.title}</Box>
          {taskGroup.condition && <Box>{taskGroup.condition}</Box>}
        </Box>
      )}

      {taskGroup.title && !taskGroup.tasksAreOptional && (
        <Box
          sx={{
            fontFamily: 'Barlow',
            textTransform: 'none',
            fontWeight: 'bold',
            color: '#999',
          }}
        >
          <Box>{taskGroup.title}</Box>
        </Box>
      )}

      {taskGroup.tasks.map((task, key) => (
        <TaskItemComponent
          task={task}
          key={key}
          onClick={handleCompleted}
          completed={completedTasks[key]}
        />
      ))}
    </Box>
  );
};
