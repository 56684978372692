import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProcedureState {
  lastTaskCompletedId: string;
  completedTaskIds: string[];
}

const initialState: ProcedureState = {
  lastTaskCompletedId: null,
  completedTaskIds: [],
};

const procedureSlice = createSlice({
  name: 'procedure',
  initialState,
  reducers: {
    lastTaskCompletedAdded(state, action: PayloadAction<string>) {
      state.lastTaskCompletedId = action.payload;
    },
    lastTaskCompletedRemoved(state) {
      state.lastTaskCompletedId = null;
    },
    completedTaskAdded(state, action: PayloadAction<string>) {
      state.completedTaskIds = state.completedTaskIds.includes(action.payload)
        ? state.completedTaskIds
        : [...state.completedTaskIds, action.payload];
    },
    completedTaskRemoved(state, action: PayloadAction<string>) {
      state.completedTaskIds = state.completedTaskIds.filter(
        (id) => id !== action.payload,
      );
    },
  },
});

export const {
  lastTaskCompletedAdded,
  lastTaskCompletedRemoved,
  completedTaskAdded,
  completedTaskRemoved,
} = procedureSlice.actions;

export default procedureSlice.reducer;
