import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useAppSelector } from '../../app/state/hooks';
import { DefaultHeader } from '../../app/layout/header/DefaultHeader';
import { DefaultFooter } from '../../app/layout/footer/DefaultFooter';

import './flight-plan.scss';

export const FlightPlanLayout: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);

  useEffect(() => {
    username === null ? navigate('/simbrief') : navigate('/');
  }, [username]);

  return (
    <Box id="flex-container">
      <DefaultHeader />
      <Box id="main">
        <Outlet />
      </Box>
      <DefaultFooter />
    </Box>
  );
};
