import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { KeyPad } from '../../app/components/input/KeyPad';
import { useLocalStorage } from '../../app/hooks/useLocalStorage';
import { useAppDispatch } from '../../app/state/hooks';
import { usernameAdded } from './state/flight-plan-slice';

export const FlightPlanUsername: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [{ data: localStorageUsername }, setValue] = useLocalStorage(
    'simbriefUsername',
    '',
  );

  const [username, setUsername] = useState<string>(() =>
    localStorageUsername && localStorageUsername.length > 0
      ? localStorageUsername
      : '',
  );

  const [requiresValidation] = useState<boolean>(() => {
    return !(localStorageUsername && localStorageUsername.length > 0);
  });

  const onChange = (input) => {
    setUsername(`${username}${input}`);
  };

  const handleOnLoadFlightPlan = (username: string) => {
    setValue(username);
    dispatch(usernameAdded(username));
  };

  useEffect(() => {
    if (username && username.length > 0 && !requiresValidation) {
      dispatch(usernameAdded(username));
    }
  }, [username]);

  return (
    <>
      <Box
        sx={{
          width: '35rem',
          border: '2px solid #182547',
          padding: '2rem',
          borderRadius: '0.5rem',
        }}
      >
        <TextField
          label="SimBrief Username"
          variant="outlined"
          inputProps={{ sx: { fontSize: '2rem' } }}
          type="text"
          fullWidth
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />

        <KeyPad
          onKeyPress={onChange}
          onClearPress={() =>
            setUsername(username.substring(0, username.length - 1))
          }
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => handleOnLoadFlightPlan(username)}
            color="primary"
            variant="contained"
          >
            Load Flight Info
          </Button>
        </Box>
      </Box>
    </>
  );
};
