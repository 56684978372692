import React from 'react';

import Box from '@mui/material/Box';

import Numkey1 from '../../../assets/keypad/Numkey_1.svg';
import Numkey2 from '../../../assets/keypad/Numkey_2.svg';
import Numkey3 from '../../../assets/keypad/Numkey_3.svg';
import Numkey4 from '../../../assets/keypad/Numkey_4.svg';
import Numkey5 from '../../../assets/keypad/Numkey_5.svg';
import Numkey6 from '../../../assets/keypad/Numkey_6.svg';
import Numkey7 from '../../../assets/keypad/Numkey_7.svg';
import Numkey8 from '../../../assets/keypad/Numkey_8.svg';
import Numkey9 from '../../../assets/keypad/Numkey_9.svg';
import Numkey0 from '../../../assets/keypad/Numkey_0.svg';

import KeypadA from '../../../assets/keypad/Keypad_A.svg';
import KeypadB from '../../../assets/keypad/Keypad_B.svg';
import KeypadC from '../../../assets/keypad/Keypad_C.svg';
import KeypadD from '../../../assets/keypad/Keypad_D.svg';
import KeypadE from '../../../assets/keypad/Keypad_E.svg';
import KeypadF from '../../../assets/keypad/Keypad_F.svg';
import KeypadG from '../../../assets/keypad/Keypad_G.svg';
import KeypadH from '../../../assets/keypad/Keypad_H.svg';
import KeypadI from '../../../assets/keypad/Keypad_I.svg';
import KeypadJ from '../../../assets/keypad/Keypad_J.svg';
import KeypadK from '../../../assets/keypad/Keypad_K.svg';
import KeypadL from '../../../assets/keypad/Keypad_L.svg';
import KeypadM from '../../../assets/keypad/Keypad_M.svg';
import KeypadN from '../../../assets/keypad/Keypad_N.svg';
import KeypadO from '../../../assets/keypad/Keypad_O.svg';
import KeypadP from '../../../assets/keypad/Keypad_P.svg';
import KeypadQ from '../../../assets/keypad/Keypad_Q.svg';
import KeypadR from '../../../assets/keypad/Keypad_R.svg';
import KeypadS from '../../../assets/keypad/Keypad_S.svg';
import KeypadT from '../../../assets/keypad/Keypad_T.svg';
import KeypadU from '../../../assets/keypad/Keypad_U.svg';
import KeypadV from '../../../assets/keypad/Keypad_V.svg';
import KeypadW from '../../../assets/keypad/Keypad_W.svg';
import KeypadX from '../../../assets/keypad/Keypad_X.svg';
import KeypadY from '../../../assets/keypad/Keypad_Y.svg';
import KeypadZ from '../../../assets/keypad/Keypad_Z.svg';
import KeypadCLR from '../../../assets/keypad/Keypad_CLR.svg';

interface Props {
  onKeyPress: (value: string) => void;
  onClearPress: () => void;
}

export const KeyPad: React.FC<Props> = ({
  onKeyPress,
  onClearPress,
}): JSX.Element => {
  const handleOnClick = (value: string): void => {
    onKeyPress(value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '34rem',
          height: '24rem',
          alignItems: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '11rem',
            height: '14rem',
            margin: '1rem',
            justifyContent: 'space-evenly',
            alignContent: 'space-between',
          }}
        >
          <img
            src={Numkey1}
            alt="Numkey1"
            height="48px"
            onClick={() => handleOnClick('1')}
          />
          <img
            src={Numkey2}
            alt="Numkey2"
            height="48px"
            onClick={() => handleOnClick('2')}
          />
          <img
            src={Numkey3}
            alt="Numkey3"
            height="48px"
            onClick={() => handleOnClick('3')}
          />
          <img
            src={Numkey4}
            alt="Numkey4"
            height="48px"
            onClick={() => handleOnClick('4')}
          />
          <img
            src={Numkey5}
            alt="Numkey5"
            height="48px"
            onClick={() => handleOnClick('5')}
          />
          <img
            src={Numkey6}
            alt="Numkey6"
            height="48px"
            onClick={() => handleOnClick('6')}
          />
          <img
            src={Numkey7}
            alt="Numkey7"
            height="48px"
            onClick={() => handleOnClick('7')}
          />
          <img
            src={Numkey8}
            alt="Numkey8"
            height="48px"
            onClick={() => handleOnClick('8')}
          />
          <img
            src={Numkey9}
            alt="Numkey9"
            height="48px"
            onClick={() => handleOnClick('9')}
          />
          <img
            src={Numkey0}
            alt="Numkey0"
            height="48px"
            onClick={() => handleOnClick('0')}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignContent: 'space-between',
            width: '17rem',
            height: '22rem',
            margin: '1rem',
          }}
        >
          <img
            src={KeypadA}
            alt="KeypadA"
            height="48px"
            onClick={() => handleOnClick('A')}
          />
          <img
            src={KeypadB}
            alt="KeypadB"
            height="48px"
            onClick={() => handleOnClick('B')}
          />
          <img
            src={KeypadC}
            alt="KeypadC"
            height="48px"
            onClick={() => handleOnClick('C')}
          />
          <img
            src={KeypadD}
            alt="KeypadD"
            height="48px"
            onClick={() => handleOnClick('D')}
          />
          <img
            src={KeypadE}
            alt="KeypadE"
            height="48px"
            onClick={() => handleOnClick('E')}
          />
          <img
            src={KeypadF}
            alt="KeypadF"
            height="48px"
            onClick={() => handleOnClick('F')}
          />
          <img
            src={KeypadG}
            alt="KeypadG"
            height="48px"
            onClick={() => handleOnClick('G')}
          />
          <img
            src={KeypadH}
            alt="KeypadH"
            height="48px"
            onClick={() => handleOnClick('H')}
          />
          <img
            src={KeypadI}
            alt="KeypadI"
            height="48px"
            onClick={() => handleOnClick('I')}
          />
          <img
            src={KeypadJ}
            alt="KeypadJ"
            height="48px"
            onClick={() => handleOnClick('J')}
          />
          <img
            src={KeypadK}
            alt="KeypadK"
            height="48px"
            onClick={() => handleOnClick('K')}
          />
          <img
            src={KeypadL}
            alt="KeypadL"
            height="48px"
            onClick={() => handleOnClick('L')}
          />
          <img
            src={KeypadM}
            alt="KeypadM"
            height="48px"
            onClick={() => handleOnClick('M')}
          />
          <img
            src={KeypadN}
            alt="KeypadN"
            height="48px"
            onClick={() => handleOnClick('N')}
          />
          <img
            src={KeypadO}
            alt="KeypadO"
            height="48px"
            onClick={() => handleOnClick('O')}
          />
          <img
            src={KeypadP}
            alt="KeypadP"
            height="48px"
            onClick={() => handleOnClick('P')}
          />
          <img
            src={KeypadQ}
            alt="KeypadQ"
            height="48px"
            onClick={() => handleOnClick('Q')}
          />
          <img
            src={KeypadR}
            alt="KeypadR"
            height="48px"
            onClick={() => handleOnClick('R')}
          />
          <img
            src={KeypadS}
            alt="KeypadS"
            height="48px"
            onClick={() => handleOnClick('S')}
          />
          <img
            src={KeypadT}
            alt="KeypadT"
            height="48px"
            onClick={() => handleOnClick('T')}
          />
          <img
            src={KeypadU}
            alt="KeypadU"
            height="48px"
            onClick={() => handleOnClick('U')}
          />
          <img
            src={KeypadV}
            alt="KeypadV"
            height="48px"
            onClick={() => handleOnClick('V')}
          />
          <img
            src={KeypadW}
            alt="KeypadW"
            height="48px"
            onClick={() => handleOnClick('W')}
          />
          <img
            src={KeypadX}
            alt="KeypadX"
            height="48px"
            onClick={() => handleOnClick('X')}
          />
          <img
            src={KeypadY}
            alt="KeypadY"
            height="48px"
            onClick={() => handleOnClick('Y')}
          />
          <img
            src={KeypadZ}
            alt="KeypadZ"
            height="48px"
            onClick={() => handleOnClick('Z')}
          />
          <img
            src={KeypadCLR}
            alt="KeypadCLR"
            height="48px"
            onClick={onClearPress}
          />
        </Box>
      </Box>
    </>
  );
};
