import React from 'react';

import Box from '@mui/material/Box';

import { Note } from '../../entities/Procedure';
import { CustomReactMarkdown } from '../../../../app/components/ui/markdown/CustomMarkdown';

interface Props {
  note: Note;
}

export const NoteComponent: React.FC<Props> = ({ note }): JSX.Element => {
  return (
    <Box className="note-component">
      {!note.isMarkdown && <Box sx={{ fontSize: '2rem' }}>{note.note}</Box>}
      {note.isMarkdown && <CustomReactMarkdown children={note.note} />}
    </Box>
  );
};
