import React, { useEffect, useState } from 'react';
import { addMinutes, format } from 'date-fns';

import Box from '@mui/material/Box';

const getUTCTime = (): string => {
  const date = new Date();
  return format(addMinutes(date, date.getTimezoneOffset()), 'HH:mm:ss');
};

export const UtcTime: React.FC = (): JSX.Element => {
  const [time, setTime] = useState<string>(getUTCTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getUTCTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box sx={{ display: 'inline' }}>
      {`${time.split(':')[0]}`}:{`${time.split(':')[1]}`}:
      {`${time.split(':')[2]}`}
    </Box>
  );
};
