export enum ProcedureType {
  Normal = 'normal',
  Abnormal = 'abnormal',
}

export interface Sop {
  id: string;
  aircraftType: string;
  name: string;
  description: string;
  isPublic: boolean;
  procedures?: Procedure[];
  revision: string;
}

export interface Procedure {
  id: string;
  title: string;
  type: ProcedureType;
  content: {
    sections: Section[];
  };
}

export interface ProcedureContent {
  sections: Section[];
}

export interface Section {
  id?: string; // Only usable for Editable Section
  title: string;
  level: number;
  items: Item[];
  isExpanded?: boolean;
}

export enum ItemType {
  TaskGroup = 'task-group',
  Checklist = 'checklist',
  Note = 'note',
  AtcComm = 'atc-comm',
}

export interface Item {
  id?: string;
  sectionId?: string;
  type: ItemType;
  tasksAreOptional: boolean;
  tasks?: Task[];
  checklistItems?: ChecklistItem[];
}

export interface TaskGroup extends Item {
  title?: string;
  condition?: string;
  tasksAreOptional: boolean;
  tasks: Task[];
}

export interface Task {
  id?: string;
  item: string;
  action: string;
  completed?: boolean;
  note?: string;
}

export interface Checklist extends Item {
  item: string;
  checklistItems: ChecklistItem[];
}

export interface Note extends Item {
  note: string;
  isMarkdown: boolean;
}

export interface AtcComm extends Item {
  title: string;
  rules: string;
  requestType: string;
}

export interface ChecklistItem {
  id?: string;
  item: string;
  action: string;
  note?: string;
}
