import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import '../sop/procedures/procedure.scss';

import { useFetchProcedureByTypeQuery } from '../../app/state/queries';

import { useAppDispatch, useAppSelector } from '../../app/state/hooks';
import { getSimbriefFlightPlanState } from '../../app/state/simbriefApi';
import { RootState } from '../../app/state/store';
import { useLocalStorage } from '../../app/hooks/useLocalStorage';
import {
  completedTaskAdded,
  lastTaskCompletedAdded,
} from './state/procedure-slice';

import { SectionComponent } from '../sop/procedures/components/Section';

export const FlightPlanProcedure: React.FC = (): JSX.Element => {
  const state = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);

  const [{ data: storageLastCompletedTaskId }, setStorageLastCompletedTaskId] =
    useLocalStorage('lastCompletedTaskId', '');

  const lastCompletedTaskId = useSelector(
    (state: RootState) => state.procedure.lastTaskCompletedId,
  );

  useEffect(() => {
    if (
      lastCompletedTaskId &&
      lastCompletedTaskId.length > 0 &&
      lastCompletedTaskId !== 'null'
    ) {
      setStorageLastCompletedTaskId(lastCompletedTaskId);
    }
  }, [lastCompletedTaskId]);

  const scrollToLastCompletedTask = (id: string) => {
    const possibleLastCompletedTask = document.getElementById(
      `task-item-${id}`,
    );

    if (possibleLastCompletedTask) {
      const effectuerScroll = () => {
        const yOffset = -150;
        const y =
          possibleLastCompletedTask.getBoundingClientRect().top +
          window.scrollY +
          yOffset;

        if (y < 1) {
          return;
        }

        document.getElementById('main').scrollTo({ top: y });
      };

      setTimeout(effectuerScroll, 50);
    }
  };

  const possibleFlightPlan = getSimbriefFlightPlanState(state, username);

  const {
    data: procedure = null,
    isLoading,
    isSuccess,
  } = useFetchProcedureByTypeQuery({
    type: possibleFlightPlan?.aircraft?.icaocode,
  });

  useEffect(() => {
    if (!isLoading && isSuccess && procedure) {
      dispatch(completedTaskAdded(storageLastCompletedTaskId));
      dispatch(lastTaskCompletedAdded(storageLastCompletedTaskId));
      scrollToLastCompletedTask(storageLastCompletedTaskId);
    }
  }, [procedure, isLoading, isSuccess]);

  return (
    <Box>
      {!isLoading && procedure && (
        <Box>
          <Box className="procedure-container">
            <Box className="procedure-title">{procedure.title}</Box>
            {procedure.content.sections.map((section, key) => (
              <SectionComponent section={section} key={key} />
            ))}
          </Box>
        </Box>
      )}

      {!isLoading && isSuccess && procedure === null && (
        <Box>Procedure not found</Box>
      )}

      {!isLoading && !isSuccess && (
        <Box sx={{ marginTop: '64px' }}>Procedure not found</Box>
      )}

      {isLoading && !isSuccess && <Box sx={{ marginTop: '64px' }}>Loading</Box>}
    </Box>
  );
};
