import React, { useState } from 'react';

import Box from '@mui/material/Box';

import { Checklist, ChecklistItem } from '../../entities/Procedure';
import { ChecklistItemComponent } from './ChecklistItem';

interface Props {
  checklist: Checklist;
}

export const ChecklistGroup: React.FC<Props> = ({ checklist }): JSX.Element => {
  const [completedTasks, setCompletedTasks] = useState<boolean[]>(
    Array(checklist.checklistItems.length).fill(false),
  );

  const handleCompleted = (
    checklistItem: ChecklistItem,
    completed: boolean,
  ): void => {
    let index = checklist.checklistItems.findIndex(
      (c) => c.id === checklistItem.id,
    );

    index = completed ? index + 1 : index;

    const workCompletedTasks = Array(checklist.checklistItems.length)
      .fill(false)
      .fill(true, 0, index);

    setCompletedTasks(workCompletedTasks);
  };

  return (
    <Box className="checklist-container">
      <Box className="title">{checklist.item}</Box>

      <hr className="divider" />
      <Box className="list-container">
        {checklist.checklistItems.map((checklistItem, key) => (
          <ChecklistItemComponent
            key={key}
            checklistItem={checklistItem}
            onClick={handleCompleted}
            completed={completedTasks[key]}
          />
        ))}
      </Box>
    </Box>
  );
};
