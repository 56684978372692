import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { SimbriefFlightPlanWeights } from '../entities/simbrief/general';

interface Props {
  weights: SimbriefFlightPlanWeights;
  units?: string;
}

export const PayloadPanel: React.FC<Props> = ({
  weights,
  units = 'lbs',
}): JSX.Element => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          PAX
        </Grid>

        <Grid item xs={4}>
          <Box className="value">{weights.pax_count}</Box>
        </Grid>

        <Grid item xs={4}>
          × {weights.pax_weight} {units}
        </Grid>

        <Grid item xs={4}>
          Cargo
        </Grid>

        <Grid item xs={4}>
          <Box className="value">{Math.round(+weights.cargo / 100) / 10}</Box>
        </Grid>

        <Grid item xs={4}>
          × 1000 {units}
        </Grid>

        <Grid item xs={4}>
          ZFW
        </Grid>

        <Grid item xs={4}>
          <Box className="value">{Math.round(+weights.est_zfw / 100) / 10}</Box>
        </Grid>

        <Grid item xs={4}>
          × 1000 {units}
        </Grid>

        <Grid item xs={4}>
          TOW
        </Grid>

        <Grid item xs={4}>
          <Box className="value">{Math.round(+weights.est_tow / 100) / 10}</Box>
        </Grid>

        <Grid item xs={4}>
          × 1000 {units}
        </Grid>
      </Grid>
    </>
  );
};
