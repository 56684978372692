import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { AtcComm } from '../../entities/Procedure';
import { useFetchAtcEventsQuery } from '../../../../app/state/queries';
import { Canvas } from '../../../../app/components/input/Canvas';
import { Instruction } from './atc/Instruction';

interface Props {
  atcComm: AtcComm;
}

export const AtcCommComponent: React.FC<Props> = ({ atcComm }): JSX.Element => {
  const [skip, setSkip] = useState(true);

  const {
    data: atcEvents = undefined,
    isFetching: isFetching,
    isError: isError,
  } = useFetchAtcEventsQuery(
    {
      rules: atcComm.rules,
      requestType: atcComm.requestType,
      language: 'en', // TODO: Switch language at user will
    },
    { skip: skip },
  );

  useEffect(() => {
    if (atcComm !== undefined) {
      setSkip(false);
    }
  }, [atcComm]);

  return (
    <>
      <Box className="atc-component">
        <Box sx={{}} className="atc-header">
          <Box sx={{ padding: '0.5rem 1.5rem', color: '#24631D' }}>ATC</Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#D9EECC',
              color: 'darkgreen',
              padding: '0.5rem 1.5rem',
            }}
          >
            <Box sx={{ fontWeight: 'Bold' }}>{atcComm.title}</Box>
            {!isFetching && !isError && atcEvents && atcEvents.length > 0 && (
              <Box>{atcEvents[0].control}</Box>
            )}
          </Box>
        </Box>

        {!isFetching &&
          !isError &&
          atcEvents &&
          atcEvents.length > 0 &&
          atcEvents[0].instructions.map((instruction, key) => (
            <Instruction instruction={instruction} key={key} />
          ))}

        {atcEvents && atcEvents.length > 0 && atcEvents[0].displayScratchpad && (
          <Box sx={{ padding: '1rem', backgroundColor: '#D9EECC' }}>
            <Canvas width={1200} height={300} />
          </Box>
        )}
      </Box>
    </>
  );
};
