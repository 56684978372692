import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { Task } from '../../entities/Procedure';
import { useAppDispatch, useAppSelector } from '../../../../app/state/hooks';
import {
  completedTaskAdded,
  completedTaskRemoved,
} from '../../../flight-plan/state/procedure-slice';

interface Props {
  task: Task;
  completed: boolean;
  onClick: (task: Task, completed: boolean) => void;
}

export const TaskItemComponent: React.FC<Props> = ({
  task,
  completed,
  onClick,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const isCompletedInStore = useAppSelector((state) =>
    state.procedure.completedTaskIds.includes(task.id),
  );

  const [_completed, setCompleted] = useState<boolean>(isCompletedInStore);

  useEffect(() => {
    setCompleted(completed);
  }, [completed]);

  useEffect(() => {
    setCompleted(_completed);

    if (_completed) {
      if (!isCompletedInStore) {
        dispatch(completedTaskAdded(task.id));
      }
    } else {
      if (isCompletedInStore) {
        dispatch(completedTaskRemoved(task.id));
      }
    }
  }, [_completed]);

  return (
    <>
      <Box sx={{ position: 'relative' }} id={`task-item-${task.id}`}>
        <span
          className={`${_completed ? 'task-done' : ''}`}
          onClick={() => onClick(task, !_completed)}
        >
          {_completed && <div className="line-through">&nbsp;</div>}
          <span className="item">{task.item}</span>
          {task.note && <span className="note">{task.note}</span>}
          <span className="action">{task.action}</span>
        </span>
      </Box>
    </>
  );
};
