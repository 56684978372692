import { useSelector } from 'react-redux';

import { useAppSelector } from '../../../../app/state/hooks';
import {
  getSimbriefFlightPlanState,
  SimbriefFlightPlan,
} from '../../../../app/state/simbriefApi';
import { RootState } from '../../../../app/state/store';
import { getNestedValueByPath } from '../../../../app/utility-functions/getNestedValueByPath';
import { transformMappings } from './transformMappings';

const transform = (inputText: string, flightPlan: SimbriefFlightPlan) => {
  const result: string[] = [];
  let nextStart = 0;
  let tagMatch;

  const regex = /{(\w+)}/g;

  do {
    tagMatch = regex.exec(inputText);

    if (tagMatch) {
      const placeholder = tagMatch[1];

      const before = inputText.substring(nextStart, tagMatch.index);
      if (before) {
        result.push(before);
      }

      const mapping = transformMappings[placeholder];

      let value = `{${placeholder}}`;

      if (mapping !== undefined) {
        value = getNestedValueByPath(
          flightPlan,
          transformMappings[placeholder],
        );
      }

      result.push(value);
      nextStart = tagMatch.index + tagMatch[0].length;
    }
  } while (tagMatch);

  if (nextStart) {
    const after = inputText.substring(nextStart);

    if (after) {
      result.push(after);
    }
  }

  return result;
};

export const useTransformText = (text: string): { value: string } => {
  const state = useSelector((state: RootState) => state);
  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);
  const possibleFlightPlan = getSimbriefFlightPlanState(state, username);

  if (!possibleFlightPlan) {
    return { value: text };
  }

  const value = transform(text, possibleFlightPlan);

  return { value: value.join('') };
};
