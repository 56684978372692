import { useState } from 'react';

export const useLocalStorage = (
  key: string,
  initialValue?: string,
): [
  { data: string | undefined; isError: boolean },
  (value: string | undefined) => void,
  () => void,
] => {
  const [isError, setIsError] = useState<boolean>(false);

  if (!window.localStorage) {
    setIsError(true);
  }

  const [storedValue, setStoredValue] = useState<string | undefined>(() => {
    if (!isError) {
      const item = window.localStorage.getItem(key);
      return item ? item : initialValue;
    }
    return isError ? undefined : initialValue;
  });

  const setValue = (value: string | undefined): void => {
    if (isError) {
      return;
    }

    if (typeof value === 'undefined') {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, value);
    }

    setStoredValue(value);
  };

  const removeItem = () => {
    window.localStorage.removeItem(key);
  };

  return [{ data: storedValue, isError }, setValue, removeItem];
};
