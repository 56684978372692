import React from 'react';

import Box from '@mui/material/Box';

import {
  SimbriefFlightPlanAircraft,
  SimbriefFlightPlanGeneral,
  SimbriefFlightPlanParams,
} from '../entities/simbrief/general';
import { UtcTime } from '../components/UtcTime';

import RouteArrowFreight from '../../../assets/planning/route-arrow-freight.svg';
import Plane from '../../../assets/planning/plane.svg';

import { useGetCurrentCycleQuery } from '../../../app/state/queries';

interface Props {
  general: SimbriefFlightPlanGeneral;
  aircraft: SimbriefFlightPlanAircraft;
  params: SimbriefFlightPlanParams;
  origin: string;
  destination: string;
}

export const FlightHeaderPanel: React.FC<Props> = ({
  general,
  aircraft,
  params,
  origin,
  destination,
}): JSX.Element => {
  const {
    data: cycle = null,
    isFetching,
    isSuccess,
  } = useGetCurrentCycleQuery();

  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const date = new Date(+params.time_generated * 1000);

  const hour = date.getUTCHours();

  let hourStr = `${hour}`;

  if (hour < 10) {
    hourStr = `0${hourStr}`;
  }

  const minutes = date.getUTCMinutes();

  let minutesStr = `${minutes}`;

  if (minutes < 10) {
    minutesStr = `0${minutesStr}`;
  }

  const day = date.getUTCDate();

  let dayStr = `${day}`;

  if (day < 10) {
    dayStr = `0${dayStr}`;
  }

  const month = date.getUTCMonth();

  const monthStr = months[month];

  const year = date.getUTCFullYear();

  const yearStr = `${year}`.substring(2);

  return (
    <Box className="flight-header">
      <Box>
        <Box className="airports">
          <Box className="flight-number">
            {general.icao_airline}
            {general.flight_number}
          </Box>
          <Box>{origin}</Box>
          <Box>
            <img src={RouteArrowFreight} alt="Freight" height="48px" />
          </Box>
          <Box>{destination}</Box>
        </Box>
      </Box>

      <Box className="general-time">
        <Box className="name">Release</Box>
        <Box className="time">
          {hourStr}
          {minutesStr} {dayStr}
          {monthStr}
          {yearStr}
        </Box>
      </Box>

      <Box className="airac">
        <Box className="name">AIRAC</Box>
        {!isFetching && isSuccess && (
          <Box
            className={`cycle ${
              cycle?.ident !== params.airac ? 'not-current' : ''
            }`}
          >
            {params.airac}
          </Box>
        )}

        {isFetching && !isSuccess && (
          <Box className="cycle">{params.airac}</Box>
        )}
      </Box>

      <Box className="utc-time">
        <Box className="name">Current UTC</Box>
        <Box className="time">
          <UtcTime />
        </Box>
      </Box>

      <Box className="aircraft">
        <Box className="icon">
          <img src={Plane} height="60px" />
        </Box>
        <Box
          sx={{
            fontSize: '3rem',
            fontFamily: 'Barlow Condensed',
            position: 'relative',
            height: '4.5rem',
            padding: '1.25rem 0 0 0',
          }}
        >
          <Box className="aircraft-icao">{aircraft.icaocode}</Box>
          <Box className="aircraft-reg">{aircraft.reg}</Box>
          <Box
            sx={{
              minWidth: '12rem',
              textAlign: 'right',
            }}
          >
            {aircraft.name}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
