import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import logo from '../../../../assets/SimOpsLogo.svg';

export const Logo: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        pl: 1,
        cursor: 'pointer',
      }}
      onClick={() => navigate('/')}
    >
      <img src={logo} alt="logo1" height="46px" />
    </Box>
  );
};
