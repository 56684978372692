import React from 'react';

import Box from '@mui/material/Box';

interface Props {
  name: string;
  timeString: string;
}

export const SchedTime: React.FC<Props> = ({
  name,
  timeString,
}): JSX.Element => {
  const date = new Date(+timeString * 1000);

  const hour = date.getUTCHours();

  let hourStr = `${hour}`;

  if (hour < 10) {
    hourStr = `0${hourStr}`;
  }

  const minutes = date.getUTCMinutes();

  let minutesStr = `${minutes}`;

  if (minutes < 10) {
    minutesStr = `0${minutesStr}`;
  }

  return (
    <>
      <Box className="sched-time">
        <Box className="name">{name}</Box>
        <Box className="time">
          {hourStr}
          {minutesStr}
        </Box>
      </Box>
    </>
  );
};
