import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useFetchApiHealthQuery } from '../../app/state/queries';

export const Version: React.FC = () => {
  const { data: version, isFetching, isError } = useFetchApiHealthQuery();

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle2">
          v{process.env.REACT_APP_VERSION} ({process.env.REACT_APP_ENV})
        </Typography>
        {!isFetching && !isError && (
          <Typography variant="subtitle2">
            API v{version.version}, {version.status}
          </Typography>
        )}
        {!isFetching && isError && <Typography>Check API Status</Typography>}
      </Box>
    </>
  );
};
