import React from 'react';

import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useGetJsonFlightPlanQuery } from '../../app/state/simbriefApi';

import { useAppSelector } from '../../app/state/hooks';
import { InfoPanel } from '../../app/components/ui/InfoPanel';

import { AirportPanel } from './panels/AirportPanel';
import { FuelPanel } from './panels/FuelPanel';
import { PayloadPanel } from './panels/PayloadPanel';
import { PerformancePanel } from './panels/PerformancePanel';
import { FlightHeaderPanel } from './panels/FlightHeaderPanel';

export const FlightPlanBriefing: React.FC = (): JSX.Element => {
  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);

  if (username === null) {
    return (
      <>
        <Typography>
          Cannot load briefing, Simbrief username is not available
        </Typography>
      </>
    );
  }

  const {
    data: flightPlan,
    isFetching,
    isSuccess,
  } = useGetJsonFlightPlanQuery(username);

  return (
    <>
      {!isFetching && isSuccess && (
        <Box className="flight-plan">
          {/* <FlightPlanSections
            toggleOFP={() => setShowOFP(!showOFP)}
            showOFP={showOFP}
          /> */}

          <FlightHeaderPanel
            general={flightPlan.general}
            aircraft={flightPlan.aircraft}
            params={flightPlan.params}
            origin={flightPlan.origin.icao_code}
            destination={flightPlan.destination.icao_code}
          />

          <>
            <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={4}>
                <InfoPanel title="Departure">
                  <AirportPanel
                    type="Departure"
                    airport={flightPlan.origin}
                    times={{
                      first: { name: 'Out', time: flightPlan.times.est_out },
                      second: { name: 'Off', time: flightPlan.times.est_off },
                    }}
                  />
                </InfoPanel>
              </Grid>
              <Grid item xs={4}>
                <InfoPanel title="Destination">
                  <AirportPanel
                    type="Destination"
                    airport={flightPlan.destination}
                    times={{
                      first: { name: 'On', time: flightPlan.times.est_on },
                      second: { name: 'In', time: flightPlan.times.est_in },
                    }}
                  />
                </InfoPanel>
              </Grid>
              <Grid item xs={4}>
                <InfoPanel title="Alternate">
                  <AirportPanel
                    type="Destination"
                    airport={flightPlan.alternate}
                  />
                </InfoPanel>
              </Grid>

              <Grid item xs={4}>
                <InfoPanel title="Fuel">
                  <FuelPanel
                    fuel={flightPlan.fuel}
                    units={flightPlan.params.units}
                  />
                </InfoPanel>
              </Grid>
              <Grid item xs={4}>
                <InfoPanel title="Payload">
                  <PayloadPanel
                    weights={flightPlan.weights}
                    units={flightPlan.params.units}
                  />
                </InfoPanel>
              </Grid>
              <Grid item xs={4}>
                <InfoPanel title="Performance">
                  <PerformancePanel general={flightPlan.general} />
                </InfoPanel>
              </Grid>

              <Grid item xs={12}>
                <InfoPanel title="Route">
                  <div className="route">{flightPlan.general.route}</div>
                </InfoPanel>
              </Grid>
            </Grid>
          </>
        </Box>
      )}

      {!isFetching && !isSuccess && (
        <Box sx={{ fontSize: '1.5rem' }}>
          Something went wrong, typically provided username is incorrect.
        </Box>
      )}
    </>
  );
};
