import React from 'react';

import Box from '@mui/material/Box';

import { useGetCurrentCycleQuery } from '../../state/queries';

export const Cycle: React.FC = (): JSX.Element => {
  const { data: cycle = null, isFetching, isError } = useGetCurrentCycleQuery();

  return (
    <>
      {!isFetching && !isError && (
        <Box
          className="cycle"
          sx={{
            borderRadius: '4px',
            bgcolor: '#007C75',
            px: 1,
            py: 0.5,
            fontSize: '1.5rem',
            fontFamily: '"Barlow Condensed"',
            fontWeight: 'bold',
          }}
        >
          AIRAC {cycle.ident}
        </Box>
      )}

      {isError && (
        <Box
          className="cycle"
          sx={{
            borderRadius: '4px',
            bgcolor: '#007C75',
            px: 1,
            py: 0.5,
            fontSize: '1.5rem',
            fontFamily: '"Barlow Condensed"',
            fontWeight: 'bold',
          }}
        >
          AIRAC error
        </Box>
      )}
    </>
  );
};
