import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FlightPlanState {
  simbriefUsername: string;
}

const initialState: FlightPlanState = {
  simbriefUsername: null,
};

const flightPlanSlice = createSlice({
  name: 'flightPlan',
  initialState,
  reducers: {
    usernameAdded(state, action: PayloadAction<string>) {
      state.simbriefUsername = action.payload;
    },
    usernameRemoved(state) {
      state.simbriefUsername = null;
    },
  },
});

export const { usernameAdded, usernameRemoved } = flightPlanSlice.actions;

export default flightPlanSlice.reducer;
