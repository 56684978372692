import React from 'react';

import Box from '@mui/material/Box';
import CellTowerIcon from '@mui/icons-material/CellTower';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';

import { AtcInstruction } from '../../../entities/AtcEvent';
import { useTransformText } from '../../hooks/useTransformText';

interface Props {
  instruction: AtcInstruction;
}

export const Instruction: React.FC<Props> = ({ instruction }): JSX.Element => {
  const { value: transformedText } = useTransformText(instruction.text);

  return (
    <Box className={`atc-instruction ${instruction.source.toLowerCase()}`}>
      {instruction.source === 'Pilot' && (
        <LocalAirportIcon sx={{ margin: '0 1rem', fontSize: '2.5rem' }} />
      )}

      {instruction.source === 'ATC' && (
        <CellTowerIcon sx={{ margin: '0 1rem', fontSize: '2.5rem' }} />
      )}

      <Box>{transformedText}</Box>
    </Box>
  );
};
