import React, { useEffect, useState } from 'react';

import { addMinutes, format } from 'date-fns';

import Box from '@mui/material/Box';

import './time.scss';

interface Props {
  localtime: boolean;
  name?: string;
}

const getLocalTime = (): string => {
  return format(new Date(), 'HH:mm');
};

const getUTCTime = (): string => {
  const date = new Date();
  return format(addMinutes(date, date.getTimezoneOffset()), 'HH:mm');
};

const getTime = (localtime: boolean): string => {
  return localtime ? getLocalTime() : getUTCTime();
};

export const Time: React.FC<Props> = ({ localtime, name }): JSX.Element => {
  const [time, setTime] = useState<string>(getTime(localtime));

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getTime(localtime));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box className="time-container">
      <Box className="time">
        {`${time.split(':')[0]}`}
        <span className="timeSep">:</span>
        {`${time.split(':')[1]}`}
      </Box>
      {name !== undefined && name !== '' && <Box className="name">{name}</Box>}
    </Box>
  );
};
