import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TypographyVariant } from '@mui/material/styles';

import {
  ItemType,
  Checklist,
  Item,
  Section,
  TaskGroup,
  Note,
  AtcComm,
} from '../../entities/Procedure';

import { ChecklistGroup } from './ChecklistGroup';
import { TaskGroupComponent } from './TaskGroup';
import { AtcCommComponent } from './AtcCommComponent';
import { NoteComponent } from './NoteComponent';

interface Props {
  section: Section;
}

export const SectionComponent: React.FC<Props> = ({ section }): JSX.Element => {
  const switchItemComponent = (component: Item, key: number) => {
    switch (component.type) {
      case ItemType.TaskGroup:
        return (
          <TaskGroupComponent
            key={component.id}
            taskGroup={component as TaskGroup}
          />
        );

      case ItemType.Checklist:
        return <ChecklistGroup key={key} checklist={component as Checklist} />;

      case ItemType.Note:
        return <NoteComponent note={component as Note} key={key} />;

      case ItemType.AtcComm:
        return <AtcCommComponent atcComm={component as AtcComm} key={key} />;

      default:
        return <Box key={key}>No valid type of item</Box>;
    }
  };

  const getTitleProps = (
    level: number,
  ): { className: string; variant: TypographyVariant } => {
    switch (level) {
      case 1:
        return { className: 'section-title-l1', variant: 'h1' };
      case 2:
        return { className: 'section-title-l2', variant: 'h2' };
      default:
        return { className: 'section-title-l1', variant: 'h1' };
    }
  };

  return (
    <>
      <Typography {...getTitleProps(section.level)}>{section.title}</Typography>

      <ul className="task-item">
        {section.items.map((item, key) => switchItemComponent(item, key))}
      </ul>
    </>
  );
};
