import React from 'react';

import Box from '@mui/material/Box';

import { useAppSelector } from '../../app/state/hooks';
import { useGetJsonFlightPlanQuery } from '../../app/state/simbriefApi';

export const FlightPlanOFP: React.FC = (): JSX.Element => {
  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);

  const {
    data: flightPlan,
    isFetching,
    isSuccess,
  } = useGetJsonFlightPlanQuery(username);

  return (
    <>
      {!isFetching && isSuccess && (
        <>
          <Box sx={{ padding: '1rem' }}>
            <Box
              className="ofp"
              sx={{ fontSize: '1.5rem' }}
              dangerouslySetInnerHTML={{ __html: flightPlan.text.plan_html }}
            ></Box>
          </Box>
        </>
      )}

      {!isFetching && !isSuccess && (
        <Box sx={{ fontSize: '1.5rem' }}>
          Something went wrong, typically provided username is incorrect.
        </Box>
      )}
    </>
  );
};
