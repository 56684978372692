import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './react-markdown.scss';

interface Props {
  children: string;
}

export const CustomReactMarkdown: React.FC<Props> = ({
  children,
}): JSX.Element => {
  return (
    <Markdown className="reactMarkdown" remarkPlugins={[remarkGfm]}>
      {children}
    </Markdown>
  );
};
