import { configureStore } from '@reduxjs/toolkit';

import { backendApiSlice } from './queries';

import flightPlanReducer from '../../features/flight-plan/state/flight-plan-slice';
import procedureReducer from '../../features/flight-plan/state/procedure-slice';

import { simbriefApiSlice } from './simbriefApi';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    [backendApiSlice.reducerPath]: backendApiSlice.reducer,
    flightPlan: flightPlanReducer,
    [simbriefApiSlice.reducerPath]: simbriefApiSlice.reducer,
    procedure: procedureReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(backendApiSlice.middleware)
      .concat(simbriefApiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
