import React from 'react';

import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  linkText: string;
  link: string;
  active: boolean;
  end: boolean;
  disabled?: boolean;
}

export const MenuItem: React.FC<Props> = ({
  linkText,
  link,
  end,
  disabled = false,
}): JSX.Element => {
  const resolved = useResolvedPath(link);
  const match = useMatch({ path: resolved.pathname, end });

  return (
    <Box sx={{ mx: 1, my: 0, mb: 0.5 }}>
      <Typography>
        <Link
          to={link}
          className={`menu-item ${match ? 'active' : ''} ${
            disabled ? 'disabled' : ''
          }`}
        >
          {linkText}
        </Link>
      </Typography>
    </Box>
  );
};
